import React from 'react'

const NotPage = () => {
  return (
    <div>
      <h1>Not Page Found</h1>
    </div>
  )
}

export default NotPage
