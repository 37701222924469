import React from 'react'

export default function Footer() {
  return (
    <>
      <footer className="footer">
        <p>&copy; 2024 Jorge R. Sepúlveda Turpie</p>
      </footer>
    </>
  )
}
